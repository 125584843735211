.menu-icon {
    display: inline-block;
    cursor: pointer;
    width: 33px;
    z-index: 10;
    margin-left: 0px;
}

.bar1, .bar2, .bar3 {
    width: 30px;
    height: 3px;
    background-color: #000;
    margin: 6px 0;
    transform: translate(0,0) rotate(0deg);
    opacity: 1;
    transition: all 400ms cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.__active .bar1 {
    transform: translate(0,9px) rotate(45deg);
}

.__active .bar2 {
    opacity: 0;
}

.__active .bar3 {
    transform: translate(0,-9px) rotate(-45deg);
}
