@import url('https://fonts.googleapis.com/css?family=Rubik:400,900i&display=swap');

* {
  font-family: "Rubik", "Helvetica", "Arial", sans-serif;
}

.app-nav-col {
  width: 210px;
  padding: 0;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
}

.app-main-col {
  position: fixed;
  left: 210px;
  top: 0;
  bottom: 0;
  width: auto;
  overflow-y: scroll;
}

a {
  color: #525252;
  transition: color 400ms ease-in;
}

a:hover {
  color: #007bff;
  text-decoration: none;
}
 
@media only screen and (max-width: 768px) {
  .app-nav-col {
    display: none;
  }

  .app-main-col {
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    width: auto;
    overflow-y: scroll;
    padding-top: 50px;
  }
}