@import url(https://fonts.googleapis.com/css?family=Rubik:400,900i&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  font-family: "Rubik", "Helvetica", "Arial", sans-serif;
}

.app-nav-col {
  width: 210px;
  padding: 0;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
}

.app-main-col {
  position: fixed;
  left: 210px;
  top: 0;
  bottom: 0;
  width: auto;
  overflow-y: scroll;
}

a {
  color: #525252;
  -webkit-transition: color 400ms ease-in;
  transition: color 400ms ease-in;
}

a:hover {
  color: #007bff;
  text-decoration: none;
}
 
@media only screen and (max-width: 768px) {
  .app-nav-col {
    display: none;
  }

  .app-main-col {
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    width: auto;
    overflow-y: scroll;
    padding-top: 50px;
  }
}
.navbar-container {
    width: 100%;
    background-color: #fff;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    border-right: 1px solid #c2c2c2;
    min-width: 210px;
}

.nav-menu > h4 {
    margin: 5% 0 10% 0;
    font-weight: bold;
}

.nav-menu {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.nav-ul {
    width: 70%;
    margin: 0;
    padding: 0;
}

.nav-ul > li {
    list-style-type: none;
    margin: 10px 0;
    padding: 0;
    width: 100%;
    text-align: center;
}

.nav-ul > li > a {
    color: #000;
}

.profile-img {
    height: 120px;
    width: 120px;
    border-radius: 50%;
    margin: 10% 0;
}

.nav-contact-me {
    width: 70%;
    font-size: 1.5rem;
    display: flex;
    justify-content: space-between;
    margin-bottom: 10%;
}
a.blue-a {
    color: #007bff;
}
.layout-container {
    max-width: 90%;
    height: 100%;
    padding: 5%;
}

.contact-me {
    width: 100%;
    display: flex;
    justify-content: center;
}

.projects-main {
    display: flex;
    flex-direction: column;
}

.project {
    margin-bottom: 5%;
}

.project-img {
    max-width: 100%;
    max-height: 220px;
    display: block;
    margin: 10px 0;
}

.project-title {
    display: flex;
    width: 100%;
    border-bottom: 1px solid #c2c2c2;
}

.project-title > span {
    margin-left: 5%;
    font-size: 0.6em;
    line-height: 3em;
}

.img-container {
    width: 100%;
}
.pub-card {
    font-size: 0.8em;
    width: 100%;
    padding: 5px 0 5px 20px;
    margin-bottom: 15px;
    border-radius: 10px;
    -webkit-transition: background-color 500ms ease-in;
    transition: background-color 500ms ease-in;
}
.pub-card:hover {
    background-color: #f7f7f7;
}

.pub-title {
    font-weight: bold;
    font-size: 1.2em;
    width: 100%;
    border-bottom: solid 1px #c2c2c2;
    margin-bottom: 3px;
}
.pub-title a:hover {
    color: #000;
}
.pub-squib-text {
    margin-bottom: 5px;
    padding: 0 10px 0 0;
}

.pub-details {
    width: 100%;
    display: flex;
    justify-content: space-between;
    color: #c2c2c2;
    padding: 0 10px 0 0;
}
.pub-main h4 {
    font-weight: bold;
    margin-left: 20px;
}
.pub-main.tl {
    margin-top: 50px;
}
.menu-icon {
    display: inline-block;
    cursor: pointer;
    width: 33px;
    z-index: 10;
    margin-left: 0px;
}

.bar1, .bar2, .bar3 {
    width: 30px;
    height: 3px;
    background-color: #000;
    margin: 6px 0;
    -webkit-transform: translate(0,0) rotate(0deg);
            transform: translate(0,0) rotate(0deg);
    opacity: 1;
    -webkit-transition: all 400ms cubic-bezier(0.68, -0.55, 0.265, 1.55);
    transition: all 400ms cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.__active .bar1 {
    -webkit-transform: translate(0,9px) rotate(45deg);
            transform: translate(0,9px) rotate(45deg);
}

.__active .bar2 {
    opacity: 0;
}

.__active .bar3 {
    -webkit-transform: translate(0,-9px) rotate(-45deg);
            transform: translate(0,-9px) rotate(-45deg);
}

.nav-mobile {
    position: fixed;
    top: 0;
    left: 0; 
    height: 50px; 
    z-index: 100;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    background-color: #fff;
    padding: 0 20px; 
} 

.nav {
    position: fixed;
    top: 0;
    left: 0;
    height: 50px;
    width: 100%;
    box-shadow: 0 0 8px #c2c2c2;
} 

.mobile-img {
    height: 40px;
    width: 40px;
    border-radius: 50%;
}

.mobile-head {
    font-size: 0.9em;
    font-weight: bold;
    margin: 0;
}

.dropdown-nav {
    position: fixed;
    top: 50px;
    left: 0;
    width: 100%;
    background: #fff;
    overflow: hidden;
    height: 120px;
    padding: 0; 
    -webkit-transition: all 500ms ease-out; 
    transition: all 500ms ease-out;
    box-shadow: 0 0 8px #c2c2c2;
}

.dropdown-nav > li {
    list-style: none;
    display: flex;
    justify-content: center;
    width: 100%;
    z-index: -1;
}

.dropdown-nav > li > a {
    width: 100%;
    text-align: center;
}

.slidedown-nav-enter {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%)
}
.slidedown-nav-enter-active {
    -webkit-transform: translateY(0%);
            transform: translateY(0%)
}

.slidedown-nav-exit {
    -webkit-transform: translateY(-0%);
            transform: translateY(-0%)
}
.slidedown-nav-exit-active {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%)
} 

.nav-contact-me {
    width: 170px;
    font-size: 1.5rem;
    display: flex;
    justify-content: space-between;
    margin-bottom: 10%;
}

@media only screen and (min-width: 769px) {
    .nav-mobile {
        display: none;
    }

    .nav {
        display: none;
    }

    .nav-dropdown {
        display: none;
    }
} 
